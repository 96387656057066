<template>
  <HeadNav :titles="titles" :showHd="shd" :has_share="false" :shareData="shareData" :shareDataExt="shareDataExt" @update-put-collect="doUpdatePutCollect" ref="hn"></HeadNav>
  <div class="collect">
    <van-tabs v-model:active="active" @click-tab="switchTab" @rendered="switchTab" :animated="true">
      <van-tab>
        <template #title> <van-icon :name="tab_icons[0].name" />&nbsp;我的收藏</template>
        <template #default>
          <van-list v-model:loading="loading_collect" :finished="finished_collect" finished-text="已经到底了~" class="mycollect" v-show="collect_show">
            <van-checkbox-group v-model="checkItems" ref="collectGroup">
              <div v-for="item in collect_list" :key="item">
                <van-row class="tm-view">
                  <van-col span="2">
                    <van-checkbox :name="item.id" :key="item.id" shape="square"></van-checkbox>
                  </van-col>
                  <van-col span="22" @click="this.$router.push({name:'detail',params:{id:item.id}})">
                    <van-image width="120"
                               height="90"
                               :src="item.pic" class="tm-img"></van-image>
                    <div class="tm-text">
                      <span class="tmname">{{item.tmname}}</span>
                      <p><label>类&nbsp;&nbsp;别：</label><span>{{item.intcls}}</span></p>
                      <p><label>注&nbsp;册&nbsp;&nbsp;号：</label><span>{{item.regno}}</span></p>
                      <p><label>有效期限：</label><span>{{item.privatedate}}</span></p>
                      <p><label>同名多类：</label><span>{{item.tmdl}}</span></p>
                    </div>
                  </van-col>
                  <collectWidget :tmid="item.id" @update-storage="doUpdateStorage"></collectWidget>
                </van-row>
              </div>
            </van-checkbox-group>
          </van-list>
          <van-cell class="check-view" v-if="collect_list.length>0">
            <template #title>
              <van-checkbox v-model="isCheckAll" :indeterminate="false" label-position="right" shape="square"  @click="checkAllChange">全选 {{checkItems.length||''}}</van-checkbox>
            </template>
            <template #right-icon>
              <van-button icon="cross" type="primary" square size="small" v-show="checkItems.length>0" @click="clearCollect">清空所选</van-button>&nbsp;&nbsp;&nbsp;
              <van-button :icon="require('../assets/icon/share_icon_wh.png')" type="primary" square size="small" @click="onShowShare" v-show="checkItems.length>0">
                分享已选商标
              </van-button>
            </template>
          </van-cell>
          <van-empty description="无收藏的商标" v-if="!collect_show" class="vempty"/>
        </template>
      </van-tab>
      <van-tab>
        <template #title> <van-icon :name="tab_icons[1].name" />&nbsp;我的分享</template>
        <template #default>
            <van-list @load="load_put_share" v-model:loading="loading_put_share" :finished="finished_put_share" finished-text="已经到底了~" v-show="put_share_show" class="my-share-wrap put-share">
              <van-row v-for="item in put_share_list" :key="item" class="put-view" @click="this.$router.push({name:'PutShareList',params:{id:item.id}})">
                <van-col span="24" class="time-text">{{item.share_time}}</van-col>
                <van-col span="24" class="put-con-wrap">
                  <van-icon name="link-o" color="#166ef9" style="float: left" size="16"></van-icon>
                  <p class="text-main">我的分享</p>
                  <p class="text-sub">{{item.info}}</p>
                  <span class="text-more">查看详情</span>
                </van-col>
              </van-row>
            </van-list>
            <van-empty description="无分享的商标" v-if="!put_share_show" class="vempty"/>
        </template>
      </van-tab>
      <van-tab>
        <template #title> <van-icon :name="tab_icons[2].name" />&nbsp;收到的分享</template>
        <van-list @load="load_get_share" v-model:loading="loading_get_share" :finished="finished_get_share" finished-text="已经到底了~" v-show="get_share_show" class="my-share-wrap get-share">
          <van-row v-for="item in get_share_list" :key="item" class="put-view" @click="this.$router.push({name:'GetShareList',params:{id:item.id}})">
            <van-col span="24" class="time-text">{{item.share_time}}</van-col>
            <van-col span="24" class="put-con-wrap">
              <van-icon name="link-o" color="#166ef9" style="float: left" size="16"></van-icon>
              <p class="text-main">收到的分享</p>
              <p class="text-sub">{{item.info}}</p>
              <span class="text-more">查看详情</span>
            </van-col>
          </van-row>
        </van-list>
        <van-empty description="无收到的分享" v-if="!get_share_show" class="vempty"/>
      </van-tab>
    </van-tabs>


  </div>
</template>
<style scoped>
  .vempty{
    position: relative;
    width: 100%;
    height: calc(100vh - 141px);
    background: #fff;
  }
  .tm-view{
    padding:12px 8px;
    margin:10px;
  }
  .van-tabs--line :deep(.van-tabs__wrap){
    border-bottom: 1px solid var(--van-border-color);
  }
  .tm-img{
    margin-right: 8px;
  }

  .van-tab--active i{
    color: #1989fa;
  }
  .check-view{
    position: absolute;
    bottom: 0px;
    padding:10px 20px;
    height: 53px;
    border-top: 1px solid var(--van-border-color);
  }
  .check-view :deep(.van-cell__title){
    align-content: center;
  }
  .mycollect{
    height:calc(100vh - 192px);
    padding-bottom: 50px;
    overflow:auto;
    position: relative;
  }
  .my-share-wrap{
    height:calc( 100vh - 142px);
    overflow: auto;
  }
  .put-view{
    padding:0 20px;
    font-size: 12px;
    margin:10px 0;
  }
  .time-text{
    color:#8a8a8a;
    line-height:30px;
  }
  .put-con-wrap{
    border-radius: 10px;
    background: #fff;
    padding: 12px;
    font-size: 12px;
  }
  .text-main{
    color:#166ef9;
    font-size: 26px;
    line-height:44px;
    margin: 0;
  }
  .text-sub{
    font-size: 12px;
    margin: 0;
  }
  .text-more{
    font-size: 10px;
    color:#8a8a8a;
    float: right;
  }
</style>
<script>
  import {showDialog,showToast} from 'vant';
  export default {
      data(){
        const tab_icons=[
          {name:'star-o',noact:'star-o',act:'star'},
          {name:'notes-o',noact:'notes-o',act:'notes'},
          {name:'gift-o',noact:'gift-o',act:'gift'},
        ];
        return {
          titles:this.$route.meta.title,shd:true,
          active:0,tab_icons,isCheckAll:false,
          collect_list:[],put_share_list:[],get_share_list:[],
          finished_collect:false,loading_collect:false,collect_show:true,
          finished_put_share:false,loading_put_share:false,put_share_show:true,
          finished_get_share:false,loading_get_share:false,get_share_show:true,
          collectTop:0,putshareTop:0,getshareTop:0,checkItems:[],shareData:{},shareDataExt:[],
        }
      },
      name: 'CollectView',
      methods:{
          switchTab(){
              for(let x in this.tab_icons){
                  if(this.active==x){
                      this.tab_icons[x].name=this.tab_icons[x].act
                  }else{
                      this.tab_icons[x].name=this.tab_icons[x].noact
                  }
              }
          },
          load_collect(){
            const that=this;
            let collect_tmid=JSON.parse(localStorage.getItem("locCollect"))||[];
            if(collect_tmid.length==0){
              that.loading_collect = false;
              that.finished_collect = true;
              that.collect_show=false;
              that.collect_list=[];
              that.checkItems=[];
              that.isCheckAll=false;
              return;
            }
            this.$http.post('/collect_list',{tmid:collect_tmid}).then(function(datas){
              let data=datas.data;
              if(data.list){//有数据
                for(let index in that.checkItems){
                  let in_list=false;
                  for(let didx in data.list){
                    if(data.list[didx].id==that.checkItems[index])in_list=true;
                  }
                  if(in_list==false)that.checkItems=that.checkItems.filter(item => item !== that.checkItems[index]);
                }
                //console.log(that.checkItems)
                that.collect_list=data.list;
                that.finished_collect = true;
                that.collect_show=true;
              }else if(data.page_count==0){//无数据
                that.collect_list=[]
                that.finished_collect = false;
                that.collect_show=false;
              }
              //console.log(that.collect_list)
              that.loading_collect= false;
            }).catch(error=>{
              console.log(error)
              that.$router.push({name:'login'})
            })
          },
        load_put_share(){
          const that=this;
          let PutShare=JSON.parse(localStorage.getItem("PutShare"))||[];
          that.put_share_list=PutShare.reverse();
          that.loading_put_share = false;
          that.finished_put_share = true;
          that.put_share_show=true;
          if(that.put_share_list.length==0)that.put_share_show=false
        },
        load_get_share(){
          const that=this;
          let GetShare=JSON.parse(localStorage.getItem("GetShare"))||[];
          that.get_share_list=GetShare.reverse();
          that.loading_get_share = false;
          that.finished_get_share = true;
          that.get_share_show=true;
          if(that.get_share_list.length==0)that.get_share_show=false
        },
        //显示分享层
        onShowShare(){
            this.shareData={collect_data:this.checkItems};
            let shareDataExt=[];
            for (let idx in this.collect_list){
              if(this.checkItems.includes(this.collect_list[idx].id))shareDataExt.push(this.collect_list[idx])
            }
            this.shareDataExt=shareDataExt;
            setTimeout(()=>{
              this.$refs.hn.showSharePop()
            },10)
        },
        //清空所选
        clearCollect(){
          localStorage.setItem("locCollect",JSON.stringify([]));
          this.load_collect()
          showToast('清空成功');
        },
        //更新推送的分享
        doUpdatePutCollect(){
          this.load_put_share();
        },
        checkAllChange(){
          this.$refs.collectGroup.toggleAll(this.isCheckAll)
        },
        //更新收藏的商标
        doUpdateStorage(){
          this.load_collect();
        },

      },
      created(){
        //console.log(this.$route.params);
        let sid=this.$route.params.sid;
        const that=this;
        if(sid){
          this.active=2;

          this.$http.post("/get_share_collect",{sid:sid}).then(function(data){
            let GetShare=JSON.parse(localStorage.getItem("GetShare"))||[];
            let in_share=false;
            for(let idx in GetShare){
              if(GetShare[idx].id==sid)in_share=true;
            }
            if(in_share==false){//没有重复的才能增加
              let storage_data={
                id:sid,
                data:data.data,
                info:"共计"+data.data.length+"件商标",
                share_time:data.share_time,
              };
              GetShare=GetShare.concat(storage_data);
              localStorage.setItem("GetShare",JSON.stringify(GetShare));
            }
            that.load_get_share();
            showDialog({
              message:"收到分享 共计"+data.data.length+"件商标",
            }).then(() => {
              that.$router.push({name:"GetShareList",params:{id:sid}})
            });

          }).catch(error=>{
            console.log(error)
            that.$router.push({name:'login'})
          })
        }
        //this.load_collect();
      },
      activated() {
          //console.log('activated')
          this.load_collect();
          if(this.collectTop)document.querySelector(".mycollect").scrollTop=this.collectTop;
          if(this.putshareTop)document.querySelector(".put-share").scrollTop=this.putshareTop;
          if(this.getshareTop)document.querySelector(".get-share").scrollTop=this.getshareTop;
      },
      // 离开该路由前
      beforeRouteLeave(to, from, next) {
          if(document.querySelector(".mycollect"))this.collectTop=document.querySelector(".mycollect").scrollTop|| 0;
          if(document.querySelector(".put-share"))this.putshareTop=document.querySelector(".put-share").scrollTop|| 0;
          if(document.querySelector(".get-share"))this.getshareTop=document.querySelector(".get-share").scrollTop|| 0;
          next()
      },
  }
</script>