import axios from 'axios';

// 创建axios实例
const http = axios.create({
    baseURL: 'http://127.0.0.1', // 基础URL
    timeout: 10000, // 请求超时时间
    withCredentials: true,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept': 'application/json',
    },
    // 其他配置...
});

// 请求拦截器
http.interceptors.request.use(
    config => {
        // 可以在这里添加认证token等
        // if (store.getters.token) {
        //   config.headers['Authorization'] = `Bearer ${store.getters.token}`;
        // }
        let token=config.headers.Authorization||localStorage.getItem("TOKEN")
        if(token) {
            config.headers['Authorization'] = token
        }
        return config;
    },
    error => {
        // 请求错误处理
        return Promise.reject(error);
    }
);

// 响应拦截器
http.interceptors.response.use(
    response => {
        // 对响应数据做处理，例如只返回data部分
        if(response.data.errcode>400){
            //window.location.href='/login';
            return Promise.reject(response.data);
        }
        return response.data;
    },
    error => {
        // 响应错误处理
        return Promise.reject(error);
    }
);
http.defaults.withCredentials=true
export default http;