<template>
    <canvas width="80" height="16" :ref="addRegno" :regno="regno" hasvas="0"></canvas>
</template>
<style>

</style>
<script>
    export default {
        data(){
            return {}
        },
        name:'canvas_regno',
        props: {
            regno:String,
        },
        methods:{
            addRegno(canvas){
                //console.log(canvas,typeof canvas)
                if(canvas){
                    const regno=canvas.getAttribute('regno')
                    const hasvas=canvas.getAttribute('hasvas')
                    if(hasvas==0){
                        const ctx = canvas.getContext('2d');
                        ctx.font = '100 12px sans-serif';
                        //ctx.fontFamily="Avenir, Helvetica, Arial, sans-serif"
                        ctx.fillStyle = '#000000';
                        ctx.fillText(this.$base64.decode(regno.toString()), 1, 12);
                        canvas.setAttribute("hasvas",1)
                    }
                }
            },
        }
    }
</script>