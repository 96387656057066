<template>
    <van-button :type="btn_attr[has_collect].type" size="mini" square  class="abs" @click="doCollect">{{btn_attr[has_collect].text}}</van-button>
</template>
<style scoped>
    .abs{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 10px;
        width:45px;
    }
</style>
<script>
export default {
    props:{
        tmid:String,
        validator(value) {
            return parseInt(value)
        }
    },
    data(){
        return {
            has_collect:this.checkHasCollect(),
            btn_attr:{
                false:{type:'primary',text:"+\xa0收藏"},
                true:{type:'danger',text:'已收藏'},
            }
        }

    },
    methods:{
        doCollect(){
            let collect=JSON.parse(localStorage.getItem("locCollect"))||[];
            if(collect.includes(this.tmid)){
                collect=collect.filter(item=>item!==this.tmid)
                this.has_collect=false
            }else{
                collect=collect.concat(this.tmid);
                this.has_collect=true
            }
            localStorage.setItem("locCollect",JSON.stringify(collect));
            this.$emit('updateStorage')
        },
        checkHasCollect(){
            let collect=JSON.parse(localStorage.getItem("locCollect"))||[];
            if(collect.includes(this.tmid)){
                return true
            }else{
                return false
            }
        }
    },
    activated(){
        let collect=JSON.parse(localStorage.getItem("locCollect"))||[];
        if(collect.includes(this.tmid)){
            this.has_collect=true
        }else{
            this.has_collect=false
        }
    }
}
</script>