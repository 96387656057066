<template>

  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.fullPath"  v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component :is="Component" :key="$route.fullPath"  v-if="!$route.meta.keepAlive"/>
  </router-view>

  <van-tabbar v-model="activeTab" :placeholder="true" class="app_tabbar">
    <van-tabbar-item icon="orders-o" name="home" :to="{name:'home'}">商标列表</van-tabbar-item>
    <van-tabbar-item icon="search" name="search" :to="{name:'search'}">商标搜索</van-tabbar-item>
    <van-tabbar-item icon="list-switching" name="draft" :to="{name:'draft'}">拼图</van-tabbar-item>
    <van-tabbar-item icon="star-o" name="collect" :to="{name:'collect'}">我的收藏</van-tabbar-item>
  </van-tabbar>

</template>

<style>
</style>
<script>
  export default {
    data(){
      return {activeTab:'home'}
    },
    watch: {
      $route() {
        let urlname=this.$route.name;
        //console.log(this.$route.params)

        //控制tabbar组件子页面的高亮
        if(['home','search','collect','draft'].includes(urlname)){
          this.activeTab=urlname;
        }
      }
    },
    methods:{
    }
  }
</script>
